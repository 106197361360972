const barnkids = [
  { name: "About Us", to: "/about" },
  {
    name: "Nursery",
    dropDown: [
      { name: "Nest", to: "/nest" },
      { name: "Hatchery", to: "/hatchery" },
    ],
  },
  { name: "Forest School", to: "/forest" },
  { name: "Info", to: "/info" },
  { name: "Careers", to: "https://www.barnkidsnurseries.co.uk/careers", ext:true },
]

const fairlands = [
  {name: "Information", 
  dropDown: [
    {name:"Team", to: "/team"},
    {name:"Fees", to:"/fees"},
    {name:"Food", to:"/food"},
    {name:"Blog", to: "https://www.barnkidsnurseries.co.uk/blog"},
    {name:"Policies", to:"/policies"}
  ]},
  {name:"Careers", to:"https://www.barnkidsnurseries.co.uk/careers"},
  {name:"Register", to:"/register"},
  {name:"01329 834 848", to:"tel:01329834848", ext:true}
]

export {barnkids, fairlands}